.favoritesContainer{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.albumTile{
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 145px;
}

.albumCover{
	width: 145px;
}

.movieTile{
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 145px;
}

.moviePoster{
	width: 145px;
}

@media screen and (max-width: 768px) {
	.albumTile{
		width: 120px;
	}
	.albumCover{
		width: 120px;
	}

	.movieTile{
		width: 120px;
	}
	.moviePoster{
		width: 120px;
	}
}