.landing-container {
	background: url('../../assets/gifs/galaxy.gif');
	min-height: 100vh;
	max-width: 100vw;
	margin: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.entry-background{
	background: rgb(255,255,255);
	background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
	padding: 80px 100px 80px 100px;
	border-radius: 50%;
}

.entry-link{
	text-align: center;
}

#fairy1{
	position: absolute;
	top: 0;
	left: 0;
}

#angelflowers{
	position: fixed;
	top: 0;
	right: 0;
}

#moonfairy{
	position: fixed;
	bottom: 0;
	right: 0;
}

#unicorn{
	position: fixed;
	bottom: 0;
	left: 0;
}


@media screen and (max-width: 768px) {
	#fairy1{
		width: 35%;
	}

	#angelflowers{
		width: 35%;
	}

	#unicorn{
		width: 35%;
	}

	#moonfairy{
		width: 30%;
	}

	#birdflowers{
		width: 70%;
	}

	#entre{
		width: 40%;
	}

}