.sidebar {
	margin-top: 20px;
	margin-right: 10px;
	width: 15%;
	height: 100%;

	padding: 10px;
	border-style: solid;
	background-color: #FFF2D4;
}

.sidebarHeader{
	margin: 0px;
}

.collection{
	margin: 0px;
	padding: 0px;
}

@media screen and (max-width: 768px) {
	.sidebar{
		width: 22%;
		margin-top: 45px;
		margin-right: 0px;
		margin-left: 1px;
		padding: 2px;
	}

	.sidebarHeader{
		font-size: 16px;
	}

	.collection{
		display: flex;
		flex-wrap: wrap;
	}

	#envelopeGif{
		transform: scale(0.8);
	}
}