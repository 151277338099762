#hiImGrace{
	font-family: "Royal Acidbath Open";
    font-size: 72px;
	font-weight: bold;
	margin: 0px;
}

.mainHeaderLetter{
	color: var(--main-text-color);
    transition: 0.2s ease-in;
}

.mainHeaderLetter:hover{
	color: var(--orange-accent);
    transition: 0.2s ease-out;
}

#cow{
	width: 40%;
}

#pinkPanther{
	height: 130px;
}

@media only screen and (max-width: 768px) {
	#hiImGrace{
		font-size: 60px;
	}

	#cow{
		width: 90%;
	}

}